




import LookUp from '@/components/lookup/Index.vue'
import { authPermissions } from '@/constants'
import fieldsObj from '@/views/Tenant.json'
import Vue from 'vue'

export default Vue.extend({
    name: 'Tenant',
    components: {
        LookUp
    },
    data(){
        return {
            options:{
                name: 'tenants',
                api: '/Tenant/GetTenants',
                exportAPI: {
                    api: '/Tenant/ExportTenants',
                    requiredPermissions: [authPermissions.canExportAll]
                },
                pivotTableAPI: {
                    api:'',
                    params: {
                        source: ''
                    }},
                fields:fieldsObj.fields,
                keywordSearchPlaceholder: 'Enter Tenant ID or Tenant Name or Parent ID or Parent Name or Property Address'
            }
        }
    },
})
